import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import ModuleSubmenuNav from "../modules/module-submenu-nav";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

import { slugify } from "../helpers/helpers";

const TemplateSolution = ({ data, location, pageContext }) => {
  const { contentfulTemplateSolution: page } = data;

  let relatedInsights = null;
  if (data.relatedInsights) {
    relatedInsights = data.relatedInsights.nodes;
  }

  let relatedCaseStudies = null;
  if (data.relatedCaseStudies) {
    relatedCaseStudies = data.relatedCaseStudies.nodes;
  }

  let relatedTeardowns = null;
  if (data.relatedTeardowns) {
    relatedTeardowns = data.relatedTeardowns.nodes;
  }
  const relatedContentProps = {
    relatedInsights: relatedInsights,
    relatedCaseStudies: relatedCaseStudies,
    relatedTeardowns: relatedTeardowns,
  };

  const mainImage = page?.mainImage;
  const metaTitle = page.seoPageTitle ? page.seoPageTitle : page.pageTitle;
  const metaDesc = page.seoPageDescription
    ? page.seoPageDescription.seoPageDescription
    : null;
  const metaImage = page?.seoShareImage?.file?.url;
  const header = page.header ? moduleConductor(page.header, 0) : null;
  const pageSlug = page?.slug;

  let submenuLinks = [];

  /* TODO: pull renderCarousels out into a module or component, like RelatedContentGroups or something,
    refactor so it isn't hardcoded to individual types */

  const renderCarousels = (subItems) => {
    const relatedServices = subItems.filter(service => service.__typename === 'ContentfulTemplateService');
    // const relatedIndustries = subItems.filter(service => service.__typename === 'ContentfulTemplateIndustry');

    let carousels = [];

    if (relatedServices.length > 0) {
      submenuLinks.push("Services");
      const servicesUpdatedSlugs = relatedServices.map((service, i) => {
        let obj = { ...service, slug: `/services/${service.slug}` };
        return obj;
      });
      
      carousels.push({items: servicesUpdatedSlugs, title: 'Services'});
    }

/*     if (relatedIndustries.length > 0) {
      submenuLinks.push("Industries");
      const industriesUpdatedSlugs = relatedIndustries.map((industry, i) => {
        let obj = { ...industry, slug: `industries/${industry.slug}` };
        return obj;
      });

      carousels.push({items: industriesUpdatedSlugs, title: 'Industries'});
    } */

    const carouselReturn = carousels.map((carousel, i) => {
      return (
        <div className="template-solution__related-services">
          <ModuleCarousel
            numDesktopSlides={3}
            cards={carousel.items}
            moduleHeadline={carousel.title}
            submenuTitle={carousel.title}
          />
        </div>
      );
    });

    return carouselReturn;
  }

  const modules = page.modules
    ? page.modules.map((module, i) => {

        if (module.submenuTitle) {
          submenuLinks.push(module.submenuTitle);
        }
        // Insert Services and Industries carousels before CTA Banner, if Services and Industries carousels exist
        if (
          module.__typename === "ContentfulModuleCtaBanner" &&
          pageContext.subServices
        ) {
          /*
            Potential BUG: if a CTA Banner doesn't exist, carousels don't render;
            Confirm with UnitedLex if this is the desired behavior
          */
          return (
            <React.Fragment>
              {renderCarousels(pageContext.subServices)}
              {moduleConductor(module, i, relatedContentProps)}
            </React.Fragment>
          );
        }

        return moduleConductor(module, i, relatedContentProps);
      })
    : null;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  return (
    <Layout isSidebar="contact" location={location}>
      <SwiftType
        customTitle={metaTitle}
        customDesc={metaDesc}
        customFeatImg={metaImage || mainImage?.file?.url}
      />
      <SEO
        title={metaTitle}
        description={metaDesc}
        url={location.href}
        image={metaImage || mainImage?.file?.url}
      />
      {header}
      {submenuLinks.length > 0 ? (
        <ModuleSubmenuNav
          items={submenuLinks.length > 0 ? submenuLinks : null}
        />
      ) : null}
      {modules}
    </Layout>
  );
};

export default TemplateSolution;

//query($slug: String!, $solutionTitle: String!) {
export const pageQuery = graphql`
  query($slug: String!, $today: Date) {
    contentfulTemplateSolution(slug: { eq: $slug }) {
      slug
      pageTitle
      doNotIndex
      seoPageTitle
      seoPageDescription {
        seoPageDescription
      }
      seoShareImage {
        ...ContentfulAssetFragment
      }
      header {
        ...ModuleHeaderSimpleFragment
        ...ModuleHeaderWithMediaFragment
        ...ModuleHeaderTwoColumnCardsFragment
      }
      modules {
        ...ModuleExpandingCardsFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleCtaBannerFragment
        ...ModuleSimpleSectionHeadingFragment
        ...ModuleRelatedContentFragment
        ...ModuleCarouselFragment
        ...ModuleInsightBannerFragment
        ...ModuleFeaturedBannerFragment
      }
    }
    relatedInsights: allContentfulTemplateInsight(
      filter: {
        relatedSolution: { slug: { eq: $slug } }
        insightTitle: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
      limit: 4
    ) {
      nodes {
        ...ContentfulInsightCardFragment
      }
    }
    relatedCaseStudies: allContentfulTemplateInsight(
      filter: {
        relatedSolution: { slug: { eq: $slug } }
        insightTitle: { ne: null }
        insightType: { insightType: { eq: "Case Study" } }
      }
      sort: { order: DESC, fields: publishDate }
      limit: 4
    ) {
      nodes {
        ...ContentfulInsightCardFragment
      }
    }
    relatedTeardowns: allContentfulTemplateTeardownReport(
      filter: { title: { ne: null }, publishDate: { lte: $today } }
      sort: { fields: publishDate, order: ASC }
    ) {
      nodes {
        __typename
        contentful_id
        title
        slug
        reportType
        listingDescription {
          listingDescription
        }
        price
        publishDate
        productImage {
          gatsbyImageData
          # fluid {
          #  srcSet
          # }
        }
      }
    }
  }
`;

// relatedInsightsBySolution: allContentfulTemplateInsight(
//   sort: { order: DESC, fields: publishDate }
//   filter: { relatedSolution: { pageTitle: { eq: $solutionTitle } } }
//   limit: 4
// ) {
//   nodes {
//     insightType {
//       insightType
//     }
//     publishDate
//   }
// }
